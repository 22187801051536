// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-app-index-js": () => import("/vercel/5522b067/src/app/index.js" /* webpackChunkName: "component---src-app-index-js" */),
  "component---src-templates-about-index-js": () => import("/vercel/5522b067/src/templates/about/index.js" /* webpackChunkName: "component---src-templates-about-index-js" */),
  "component---src-templates-activities-index-js": () => import("/vercel/5522b067/src/templates/activities/index.js" /* webpackChunkName: "component---src-templates-activities-index-js" */),
  "component---src-templates-amenities-index-js": () => import("/vercel/5522b067/src/templates/amenities/index.js" /* webpackChunkName: "component---src-templates-amenities-index-js" */),
  "component---src-templates-eagle-index-js": () => import("/vercel/5522b067/src/templates/eagle/index.js" /* webpackChunkName: "component---src-templates-eagle-index-js" */),
  "component---src-templates-home-index-js": () => import("/vercel/5522b067/src/templates/home/index.js" /* webpackChunkName: "component---src-templates-home-index-js" */)
}

exports.data = () => import("/vercel/5522b067/.cache/data.json")

